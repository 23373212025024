import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice"
import products from "./data/products.json";
import { getProducts } from "./data/get-products";
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import { Analytics } from '@vercel/analytics/react';
import "./i18n";


// console.log("products", getProducts());
// console.log("products", products);
const tempproducts = await getProducts();
// console.log("products", tempproducts);
// console.log("products", products);
console.log("Temp Products", tempproducts);
store.dispatch(setProducts(tempproducts.products.products));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistProvider>
      <Analytics />
      <App />
    </PersistProvider>
  </Provider>
);


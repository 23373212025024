import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";

const Logo = ({ imageUrl, logoClass }) => {
  return (
    <div className={clsx(logoClass)} style={{ marginTop: "0px" }}>
      <Link to={process.env.PUBLIC_URL + "/"}>
        {/* <img alt="" src={process.env.PUBLIC_URL + imageUrl} style={{ width: "170px", height:"100%",marginTop:"5px" }} /> */}
        {/* show the 100px width mobile and 170px width desktop */}
        <img alt="" src={process.env.PUBLIC_URL + imageUrl} className="fenil-logo-img" />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string
};

export default Logo;

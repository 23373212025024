import { lazy, Suspense } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Demo from "./pages/shop/ShopGridSearch";
import ShopGridSearch from "./pages/shop/ShopGridSearch";
const HomeElectronicsThree = lazy(() => import("./pages/home/HomeElectronicsThree"));
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
    return (<Router>
        <ScrollToTop>
            <Suspense
                fallback={<div className="flone-preloader-wrapper">
                    <div className="flone-preloader">
                        <span></span>
                        <span></span>
                    </div>
                </div>}
            >
                <Routes>
                    <Route
                        path={process.env.PUBLIC_URL + "/"}
                        element={<HomeElectronicsThree />}
                    />
                    {/* Shop pages */}
                    <Route
                        path={process.env.PUBLIC_URL + "/shop"}
                        element={<ShopGridStandard />}
                    />
                    <Route
                        path={process.env.PUBLIC_URL + "/search/:text"}
                        element={<ShopGridSearch />}
                    />
                    {/* Shop product pages */}
                    <Route
                        path={process.env.PUBLIC_URL + "/product/:id"}
                        element={<ProductSticky />}
                    />
                    {/* Other pages */}
                    {/* <Route
                        path={process.env.PUBLIC_URL + "/about"}
                        element={<About />}
                    /> */}
                    <Route
                        path={process.env.PUBLIC_URL + "/contact"}
                        element={<Contact />}
                    />
                    <Route
                        path={process.env.PUBLIC_URL + "/my-account"}
                        element={<MyAccount />}
                    />
                    <Route
                        path={process.env.PUBLIC_URL + "/login-register"}
                        element={<LoginRegister />}
                    />

                    <Route
                        path={process.env.PUBLIC_URL + "/cart"}
                        element={<Cart />}
                    />
                    <Route
                        path={process.env.PUBLIC_URL + "/wishlist"}
                        element={<Wishlist />}
                    />
                    <Route
                        path={process.env.PUBLIC_URL + "/compare"}
                        element={<Compare />}
                    />
                    <Route
                        path={process.env.PUBLIC_URL + "/checkout"}
                        element={<Checkout />}
                    />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </ScrollToTop>
    </Router>);
};

export default App;
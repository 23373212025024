import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SEO = ({ title, titleTemplate, description }) => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {title} | {titleTemplate}
                </title>
                <meta name="description" content={description} />
            </Helmet>
        </HelmetProvider>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    titleTemplate: PropTypes.string,
    description: PropTypes.string,
}

SEO.defaultProps = {
    title: "MaxPods",
    titleTemplate: "MaxPods: Elevate Your Audio Experience with Premium Sound Quality and Style - Unveiling the Ultimate Audio Brand",
    description: "Discover unparalleled audio brilliance with MaxPods, your gateway to an immersive sound experience. Our cutting-edge wireless earbuds redefine convenience, offering crystal-clear audio quality and seamless connectivity. Elevate your lifestyle with MaxPods' sleek design, delivering a perfect blend of style and comfort. Whether you're a music aficionado, gamer, or professional, MaxPods ensures a premium auditory journey. Dive into a world of innovation and precision with our top-tier audio technology. Elevate your senses and embrace a new era of superior sound – MaxPods, where excellence meets every beat.",
};

export default SEO;